.navbar {
  display: flex;
  width: 100%;
  height: 9rem;
  padding: 0 1.5rem;
  align-items: center;
  background: transparent;
  position: fixed;
  top: 0;
  transition: all 255ms ease-in-out;
  box-shadow: 0 0.1rem 1rem -0.2rem #d3d3d3;
  background-color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  z-index: 4;
}

.navbar .navbar-nav {
  display: flex;
  flex-grow: 1;
}
.navbar .navbar-nav .nav-list {
  margin-left: auto;
  display: flex;
  list-style: none;
  margin-right: 10rem;
}
.navbar .navbar-nav .nav-list .nav-item {
  padding: 0 1rem;
}

.user-profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
  color: #444444;
}

.user-name {
  margin-left: 0.5rem;
}

.login-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 5rem;
}
