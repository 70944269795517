.primary-btn {
  border-radius: 5px;
  color: rgb(55, 63, 104);
  padding: 12px 45px;
  border: none;
  background: none;
  font-size: 1.6rem;
  cursor: pointer;
}

.primary-btn-outline {
  background-color: #fff;
  color: rgb(55, 63, 104);
  border: 1px solid rgb(55, 63, 104);
}

.active {
  background-color: rgb(55, 63, 104);
  color: #fff;
}
