.modal {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6rem;
  background: rgba(51, 51, 51, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  z-index: 1000;
}
.modal-container {
  display: flex;
  max-width: 72rem;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.3s;
  background: #fff;
  transform: translateY(10rem) scale(0.4);
}
.modal-title {
  font-size: 2.6rem;
  margin: 0;
  font-weight: 400;
  color: rgb(35, 86, 231);
}
.modal-desc {
  margin: 0.6rem 0 3rem 0;
}
.modal-left {
  padding: 6rem 3rem 2rem;
  background: #fff;
  flex: 1.5;
  transition-duration: 0.5s;
  transform: translateY(8rem);
  opacity: 0;
}
.modal-button {
  color: rgb(35, 86, 231);
  font-family: "Nunito", sans-serif;
  font-size: 1.8rem;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 1rem 4rem;
  border-radius: 3rem;
  background: white;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.16);
  transition: 0.3s;
}
.modal-button:hover {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.8);
}
.modal-right {
  flex: 2;
  font-size: 0;
  transition: 0.3s;
  overflow: hidden;
}
.modal-right img {
  width: 100%;
  height: 100%;
  transform: scale(2);
  -o-object-fit: cover;
  object-fit: cover;
  transition-duration: 1.2s;
}
.modal.is-open {
  height: 100%;
  background: rgba(51, 51, 51, 0.85);
}
.modal.is-open .modal-button {
  opacity: 0;
}
.modal.is-open .modal-container {
  opacity: 1;
  transition-duration: 0.6s;
  pointer-events: auto;
  transform: translateY(0) scale(1.2);
}
.modal.is-open .modal-right img {
  transform: scale(1);
}
.modal.is-open .modal-left {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.1s;
}
.modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-buttons a {
  color: rgba(51, 51, 51, 0.6);
  font-size: 1.4rem;
}

.sign-up {
  margin: 60px 0 0;
  font-size: 1.4rem;
  text-align: center;
}
.sign-up a {
  color: rgb(35, 86, 231);
}

.input-button {
  padding: 0.8rem 1.2rem;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 0.4rem;
  background: rgb(35, 86, 231);
  transition: 0.3s;
  cursor: pointer;
}
.input-button:hover {
  background: rgb(35, 86, 231);
}

.icon-button {
  outline: 0;
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  width: 3.2rem;
  height: 3.2rem;
  border: 0;
  background: 0;
  padding: 0;
  cursor: pointer;
}

.scroll-down {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: rgb(35, 86, 231);
  font-size: 3.2rem;
  font-weight: 800;
  transform: translate(-50%, -50%);
}
.scroll-down svg {
  margin-top: 1.6rem;
  width: 5.2rem;
  fill: currentColor;
}

@media (max-width: 750px) {
  .modal-container {
    width: 90%;
  }

  .modal-right {
    display: none;
  }
}
