@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
* {
  font-family: Poppins;
}
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
  font-size: 10px;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-center {
  text-align: center;
}
