.styled-table {
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 2rem;
  min-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  background-color: white;
}

/*top bar cells*/
.styled-table thead tr {
  background-color: rgb(55, 63, 104);
  color: #ffffff;
  text-align: left;
}

/*cell shape - vertical horizontal*/
.styled-table th,
.styled-table td {
  padding: 2.5rem 2rem;
}

/*lines seperating cells*/
.styled-table tbody tr {
  border-bottom: 0.1rem solid #dddddd;
  color: #444444;
  background-color: #ffffff;
}

/*alternate cell colour*/
.styled-table tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

/*bottom line*/
.styled-table tbody tr:last-of-type {
  border-bottom: 0.5rem solid #d3d3d3;
}

/*hover effect*/
.styled-table tbody tr:hover {
  background-color: rgb(205, 210, 238);
  color: #ffffff;
}
